<template>
  <div>
    <v-card class="ma-2 calendar-page">
      <v-container fluid class="elevation-5">
        <v-row>
          <v-col cols="12">
            <v-layout row wrap align-center justify-space-between class="px-3 pt-3">
              <v-flex xs1 align-self-end v-if="!xsOnly">
                <v-btn outlined @click="getToday" v-if="$vuetify.breakpoint.smAndUp"> {{ $t('today') }} </v-btn>
                <v-btn small outlined @click="getToday" class="py-4" v-else>
                  <v-icon size="19"> mdi-calendar-refresh </v-icon>
                </v-btn>
              </v-flex>
              <v-flex :class="['text-center', $vuetify.breakpoint.smAndDown ? 'pr-3' : '']">
                <v-icon @click="goPrevious"> mdi-chevron-left </v-icon>
                <h3 class="pa-0 px-4 pt-1 px-lg-5 pt-lg-1 grey--text text--darken-3 font-weight-black d-inline-flex subtitle-1 cursor-pointer"
                @click="getToday">
                  {{ calendarTitle }}
                </h3>
                <v-icon @click="goNext"> mdi-chevron-right </v-icon>
              </v-flex>
              <v-flex xs4 lg2 xl1>
                <v-select hide-details outlined dense @change="toggleCalendarView" item-text="text" item-value="value" v-model="calendarView" :items="calendarViewItems"></v-select>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <full-calendar :options="calendarOptions" ref="calendar">
              <template #eventContent="arg">
                <b> {{ arg.timeText }} </b>
                <i> {{ arg.event.title }} </i>
              </template>
            </full-calendar>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title class="primary darken-2 white--text pa-4 pb-3">
          <span class="body-1 font-weight-medium"> {{ modelObj._id ? 'Update' : 'Create' }} Event </span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="pa-3">
          <v-form ref="eventsForm">
            <v-container fluid class="pa-0">
              <v-row no-gutters>
                <v-col cols="12" lg="6" sm="6" md="6" class="pr-sm-1 pb-2">
                  <template v-if="modelObj.type === 4">
                    <v-menu offset-y>
                      <template #activator="{ on, attrs }">
                        <v-text-field hide-details v-model="modelObj.startdate"
                          @focus="modelObj.startdate = $_formatDate(modelObj.start, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY')"
                          readonly v-bind="attrs" v-on="on" outlined dense label="From">
                        </v-text-field>
                      </template>
                      <!-- <v-date-picker v-model="modelObj.start" @change=""></v-date-picker> -->
                      <v-date-picker v-model="modelObj.start" @change="validateStartAndEndDate({ start: true })"></v-date-picker>
                    </v-menu>
                  </template>
                  <template v-else>
                    <date-time-picker label="From" v-model="modelObj.start" :key="reRenderKey"></date-time-picker>
                  </template>
                </v-col>
                <v-col cols="12" lg="6" sm="6" md="6" class="pl-sm-1 pb-2" v-if="modelObj.type === 4">
                  <v-select outlined dense hide-details :items="selectHalfItems" v-model="modelObj.startHalfSelectItem"
                  label="Select half" :disabled="modelObj.startHalfDisable" @change="validateEventPayload"></v-select>
                </v-col>
                <v-col cols="12" lg="6" sm="6" md="6" class="pl-sm-1 pb-2">
                  <template v-if="modelObj.type === 4">
                    <v-menu offset-y>
                      <template #activator="{ on, attrs }">
                        <v-text-field hide-details v-model="modelObj.enddate"
                          @focus="modelObj.enddate = $_formatDate(modelObj.end, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY')"
                          readonly v-bind="attrs" v-on="on" outlined dense label="To">
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="modelObj.end" @change="validateStartAndEndDate({ start: false })"></v-date-picker>
                    </v-menu>
                  </template>
                  <template v-else>
                    <date-time-picker label="To" v-model="modelObj.end" :key="reRenderKey"></date-time-picker>
                  </template>
                </v-col>
                <v-col cols="12" lg="6" sm="6" md="6" class="pl-sm-1 pb-2" v-if="modelObj.type === 4">
                  <v-select outlined dense hide-details :items="selectHalfItems" v-model="modelObj.endHalfSelectItem"
                  label="Select half" :disabled="modelObj.endHalfDisable" @change="validateEventPayload"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="pr-sm-1 pb-2">
                  <v-select label="Type" v-model="modelObj.type" hide-details outlined dense
                  :items="eventTypes" :rules="setValidation('requiredValidation')"></v-select>
                </v-col>
                <!-- <v-col cols="12" sm="12" md="4" lg="6" class="pl-1 pb-2" align-self="center">
                  <v-switch v-model="modelObj.publishLater" hide-details inset label="Publish later?"
                  class="mb-1" disabled></v-switch>
                </v-col> -->
                <v-col cols="12" lg="6" md="6" sm="6" class="pl-sm-1 pb-2" align-self="center">
                  <v-select outlined dense hide-details label="Repeat" v-model="modelObj.repeat"
                  :items="repeatItems">
                    <template #append-outer v-if="modelObj.repeat === 'custom'">
                      <v-icon class="cursor-pointer" title="Open dialog" @click="$refs.customRruleDialog.dialog = true">
                        mdi-open-in-app
                      </v-icon>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" class="pb-2">
                  <v-text-field outlined dense v-model="modelObj.title" label="Title" :rules="setValidation('requiredValidation')"
                    hide-details validate-on-blur>
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pb-2" v-if="modelObj.type !== 2">
                  <v-autocomplete v-model="modelObj.notifyto" :items="notifyToItems" chips :search-input.sync="notifyToSearch" label="Applicable To"
                    item-text="name" multiple dense hide-details outlined clearable return-object>
                    <template #selection="data">
                      <v-chip small outlined color="primary" v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">
                        <v-avatar size="10" left> <v-img src="https://www.pngfind.com/pngs/m/5-52097_avatar-png-pic-vector-avatar-icon-png-transparent.png"></v-img> </v-avatar> {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template #item="{ item, on, attrs }">
                      <v-list-item v-on="on" v-bind="attrs" :class="[(item.type === 'header') ? 'caption' : 'body-2', 'text-capitalize']">
                        <v-list-item-avatar :size="23" v-if="!['header', 'divider'].includes(item.name)">
                          <img :src="item.avatar">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          {{ (item.name === 'header') ? $t(item.name) : item.name }} ( {{$_getRecordType(item.model)}} )
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-2">
                  <v-textarea label="Description" v-model="modelObj.description" hide-details outlined dense
                  rows="3"></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-file-input counter="2" show-size v-model="modelObj.attachments" small-chips label="Attachments"
                  outlined dense multiple clearable @click:clear="modelObj.attachments = []">
                    <template #selection="{ index, text }">
                      <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small> {{ text }} </v-chip>
                      <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                </v-col>
                <!-- <v-col cols="12" lg="6" md="6" sm="6" class="pr-1">
                  <v-select outlined dense v-model="modelObj.notifyBefore" :items="['5 mins', '15 mins', '30 mins', '1 hour']"
                    hide-details label="Notify before" clearable disabled>
                  </v-select>
                </v-col> -->
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn small color="error" @click="deleteSingleRecordHandler(modelObj._id)" :disabled="loading" v-if="modelObj._id" class="mr-2"> Delete </v-btn>
            <v-btn small color="success darken-1" @click="saveOrUpdateHandler" :disabled="loading" class="mr-2">
              {{ modelObj._id ? 'Update' : 'Save'}}
            </v-btn>
            <v-btn small color="error darken-1" outlined @click="dialog = false; $refs.eventsForm.reset()" :disabled="loading"> Close </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <custom-rrule-dialog :start="modelObj.start" :end="modelObj.end" :payload="modelObj.rruleRawObj"
    @save="saveRruleDialog" @cancel="closeRruleDialog" ref="customRruleDialog"></custom-rrule-dialog>
  </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import rrulePlugin from '@fullcalendar/rrule'
import 'fullcalendar/dist/fullcalendar.css'
export default {
  data () {
    return {
      calendarOptions: {
        plugins: [ rrulePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin ],
        headerToolbar: false,
        initialView: 'dayGridMonth',
        height: (window.innerHeight - 155),
        events: [],
        displayEventTime: false,
        editable: false,
        selectable: true,
        eventMaxStack: 2,
        firstDay: 1,
        // weekends: true,
        // dayMaxEvents: true,
        // selectLongPressDelay: 750, // For touch devices
        // longPressDelay: 750, // For touch devices
        // forceEventDuration: false, // If an event’s end is not specified
        select: this.handleDateSelect,
        eventClick: ({ event }) => this.handleEventClick(event),
      },
      dialog: false,
      modelObj: {},
      selectHalfItems: [{
        text: 'First half',
        value: 1
      }, {
        text: 'Second half',
        value: 2
      }],
      rawModelObj: {},
      notifyToSearch: '',
      reRenderKey: 1,
      notifyToItems: [],
      calendarTitle: '',
      calendarViewItems: [{
        text: 'Month',
        value: 'dayGridMonth'
      }, {
        text: 'Week',
        value: 'dayGridWeek'
      // }, {
      //   text: 'Day',
      //   value: 'dayGridDay'
      }],
      calendarView: 'dayGridMonth',
      loading: false,
      previousRepeatValue: null
    }
  },
  components: {
    'full-calendar': FullCalendar,
    'date-time-picker': () => import('@/components/DateTimePicker.vue'),
    'custom-rrule-dialog': () => import('@/components/CustomRruleDialog.vue')
  },
  computed: {
    eventTypes () {
      return [{
      //   text: 'Announcement',
      //   value: 1
      // }, {
        text: 'Personal Reminder',
        value: 2
      }, {
        text: 'Event',
        value: 3
      }, {
        text: 'Holiday',
        value: 4
      }]
    },
    repeatItems () {
      return [{
        text: 'Does not repeat',
        value: null
      }, {
        text: 'Every weekday (Mon-Fri)',
        value: 'weekday'
      }, {
        text: 'Daily',
        value: 'daily'
      }, {
        text: 'Weekly',
        value: 'weekly'
      }, {
        text: 'Monthly',
        value: 'monthly'
      }, {
        text: 'Yearly',
        value: 'yearly'
      }, {
        text: 'Custom (beta)',
        value: 'custom',
        // disabled: true
      }]
    }
  },
  mounted () {
    this.getCalendarTitle()
    // set height
    window.onresize = () => {
      this.calendarOptions.height = window.innerHeight - 155
    }
  },
  watch: {
    'notifyToSearch' (value) {
      if (!value) return this.notifyToItems = [...this.modelObj.notifyto]
      this.$_execute('get', `find?name=${value || ''}`).then(({ data }) => {
        data.forEach((item) => {
          item.avatar = 'https://cdn.vuetifyjs.com/images/lists/1.jpg'
          item.name = (item.model === 'classes') ? `${ (item?.courseName?.name || item.name) }${ item.section ? ` - ${ item.section }` : '' }` : (item?.courseName?.name || item.name)
        })
        if (!this.modelObj.notifyto || this.modelObj.notifyto.length <= 0) this.modelObj.notifyto = []
        // const groupByModel = data.reduce((group, item) => {
        //   const { model } = item;
        //   group[model] = group[model] ?? [];
        //   group[model].push(item);
        //   return group;
        // }, {})
        // for (const key in groupByModel) {
        //   this.notifyToItems = [{ name: 'header', header: key }, { name: 'divider', divider: true }, ...groupByModel[key], ...this.modelObj.notifyto]
        // }
        this.notifyToItems = [...data, ...this.modelObj.notifyto]
      })
    },
    'modelObj.repeat': {
      handler (newValue) {
        if (newValue === 'custom') this.previousRepeatValue = this.rawModelObj.repeat
      },
      deep: true,
      immediate: true
    },
    'modelObj.type': {
      handler (value) {
        if (value) {
          this.modelObj.start = this.$_formatDate(this.modelObj.start, ((value === 4) ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD'), ((value === 4) ? 'YYYY-MM-DD' : 'YYYY-MM-DDT00:00'))
          this.modelObj.end = this.$_formatDate(this.modelObj.end, ((value === 4) ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD'), ((value === 4) ? 'YYYY-MM-DD' : 'YYYY-MM-DDT23:59'))
          if (value === 4) {
            this.modelObj.startdate = this.$_formatDate(this.modelObj.start, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY')
            this.modelObj.enddate = this.$_formatDate(this.modelObj.end, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY')
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleDateSelect ({ startStr, endStr, view }) {
      this.modelObj = {
        type: (this.userDetails.role?.code === 3) ? 2 : 3,
        notifyto: [],
        repeat: null,
        start: this.$moment(startStr, 'YYYY-MM-DD').format('YYYY-MM-DDT00:00'),
        end: this.$moment(endStr, 'YYYY-MM-DD').subtract(1, 'd').format('YYYY-MM-DDT23:59'),
        startHalfSelectItem: 1,
        endHalfSelectItem: 2
      }
      this.reRenderKey++
      this.dialog = true
      if (view.calendar) view.calendar.unselect() // clear date selection
    },
    handleEventClick ({ id, allow }) { // allow = authorID
      if (this.userDetails && (this.userDetails._id !== allow)) return
      this.$_execute('get', `events/${id}`)
        .then(async ({ data }) => {
          data.notifyto = await data.notifyto.map((item) => ({
            ...item._id,
            ...((item._id.formdata && item._id.formdata.name) && {
              name: item._id.formdata.name,
            }),
            model: item.model
          }))
          this.rawModelObj = this.$_copy(data)
          this.notifyToItems = data.notifyto && data.notifyto.length ? [...data.notifyto] : []
          this.modelObj = data
        })
        .finally(() => {
          this.reRenderKey++
          this.dialog = true
        })
    },
    validateStartAndEndDate ({ start } = {}) {
      if (start) this.modelObj.startdate = this.$_formatDate(this.modelObj.start, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY')
      else this.modelObj.enddate = this.$_formatDate(this.modelObj.end, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY')

      const diff = this.$moment(this.modelObj.end, 'YYYY-MM-DD').diff(this.$moment(this.modelObj.start, 'YYYY-MM-DD'), 'd')
      if (diff < 0) this.modelObj = Object.assign(this.modelObj, { [start ? 'end' : 'start']: null, [start ? 'enddate' : 'startdate']: null })
      this.validateEventPayload()
    },
    validateEventPayload () {
      const diff = this.$moment(this.modelObj.end, 'YYYY-MM-DD').diff(this.modelObj.start, 'd')
      if (diff === 0) {
        if (this.modelObj.startHalfSelectItem === 2) {
          this.modelObj = {
            ...this.modelObj,
            endHalfSelectItem: 2,
            endHalfDisable: true,
            startHalfDisable: false
          }
        } else if (this.modelObj.endHalfSelectItem === 1) {
          this.modelObj = {
            ...this.modelObj,
            startHalfSelectItem: 1,
            startHalfDisable: true,
            endHalfDisable: false
          }
        } else {
          this.modelObj = {
            ...this.modelObj,
            startHalfDisable: false,
            endHalfDisable: false
          }
        }
      } else {
        this.modelObj = {
          ...this.modelObj,
          startHalfDisable: false,
          endHalfDisable: false
        }
      }
    },
    remove ({ _id }) {
      const index = this.modelObj.notifyto.findIndex(x => x._id === _id)
      if (index >= 0) this.modelObj.notifyto.splice(index, 1)
    },
    goPrevious () {
      this.$refs.calendar.getApi()?.prev()
      this.getCalendarTitle()
    },
    goNext () {
      this.$refs.calendar.getApi()?.next()
      this.getCalendarTitle()
    },
    getToday () {
      this.$refs.calendar.getApi()?.today()
      this.getCalendarTitle()
    },
    getCalendarTitle () {
      let calendarApi = this.$refs.calendar.getApi()
      if (calendarApi && calendarApi.currentData && calendarApi.currentData.viewTitle) {
        this.calendarTitle = calendarApi.currentData.viewTitle
      }
      this.getListOfEvents()
    },
    toggleCalendarView () {
      this.$refs.calendar.getApi().changeView(this.calendarView)
      this.getCalendarTitle()
    },
    dayClicked (start, end, jsEvent, { name }) {
      if (this.$refs.eventsForm) this.$refs.eventsForm.reset()
      this.modelObj = {}
      const from = start.format('YYYY-MM-DD HH:mm')
      let to = null
      if (name === 'month') to = end.subtract(1, 'd').format('YYYY-MM-DD HH:mm')
      else to = end.format('YYYY-MM-DD HH:mm')
      this.modelObj.start = this.$_formatDate(from, 'YYYY-MM-DD HH:mm', 'YYYY-MM-DDTHH:mm')
      this.modelObj.end = this.$_formatDate(to, 'YYYY-MM-DD HH:mm', name === 'month' ? 'YYYY-MM-DDT23:59' : 'YYYY-MM-DDTHH:mm')
      this.modelObj.type = 1
      this.reRenderKey++
      this.dialog = true
    },
    async saveOrUpdateHandler ({ ruleObj, dialog, payload = {} }) {
      if (this.$refs.eventsForm.validate()) {
        this.loading = true
        this.modelObj.author = this.userDetails._id
        let model = this.$_copy(this.modelObj)
        model.classNames = (model.type === 4) ? 'orange darken-2' : ''
        if (model.type === 2) model.notifyto = []

        let url = model._id ? `events/${model._id}`: 'events'
        let method = model._id ? 'put': 'post'

        // rrule injection
        model.rrule = ruleObj || await this.setEventRrule()
        // model.rruleRawObj = payload || (this.modelObj.repeat === 'custom' && this.modelObj._id) ? this.rawModelObj.rruleRawObj : this.modelObj.rruleRawObj
        model.rruleRawObj = (payload && Object.keys(payload).length) ? payload : this.modelObj.rruleRawObj
        model.exdate = payload.exdate || []

        model.start = this.$_formatDate(model.start, ((model.type === 4) ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm')
        model.end = this.$_formatDate(model.end, ((model.type === 4) ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm'), ((model.type === 4) ? 'YYYY-MM-DDT23:59' : 'YYYY-MM-DDTHH:mm'))

        this.$_execute(method, url, model)
          .then(async () => {
            this.$root.$emit('snackbar', {
              message: model._id ? 'Event updated successfully' : 'Event created successfully',
              color: 'success'
            })
            this.getListOfEvents()
            // handle attachments
            if (this.modelObj.attachments && this.modelObj.attachments.length) {
              const formdata = new FormData()
              for (const file of this.modelObj.attachments) formdata.append(file.name, file)
              await this.$_execute('post', 'sharepoint/upload', formdata) || {}
              this.dialog = false
            }
          })
          .finally(() => {
            if (dialog) dialog.close()
            this.dialog = false
            this.loading = false
            this.modelObj = {}
          })
      } else {
        if (this.$refs.customRruleDialog) {
          this.modelObj.repeat = this.previousRepeatValue
          this.$refs.customRruleDialog.dialog = false
        }
        this.$root.$emit('snackbar', {
          color: 'error',
          message: 'Please check the required fields!'
        })
      }
    },
    getListOfEvents () {
      let calendarApi = this.$refs.calendar.getApi && this.$refs.calendar.getApi()
      let model = null
      if (calendarApi && calendarApi.view) {
        // let { start, end } = calendarApi.view.getCurrentData().dateProfile.currentRange
        let { activeStart: start, activeEnd: end } = calendarApi.view
        model = {
          start: this.$moment(start).format('YYYY-MM-DDT00:00'),
          end: this.$moment(end).format('YYYY-MM-DDT23:59')
        }
      }
      if (model) {
        // model = this.$_formatModelToUtcDate({ model, keys: ['start', 'end'], from: 'YYYY-MM-DDTHH:mm', to: 'YYYY-MM-DDTHH:mm' })
        this.$_execute('post', 'events/filter', model)
          .then(async ({ data } = {}) => {
            data = await data.map((date) => ({
              ...date,
              id: date._id,
              allow: date.author
            }))
            this.calendarOptions.events = [...data]
          })
      }
    },
    deleteSingleRecordHandler (id) {
      this.$root.$emit('delete', {
        module: 'events',
        _id: id,
        onResolve: () => {
          this.dialog = false
          this.$root.$emit('snackbar', { message: 'Event Deleted Successfully', color: 'success' })
          this.getListOfEvents()
        }
      })
    },
    setEventRrule () {
      return new Promise((resolve) => {
        if (this.modelObj.repeat) {
          const resolveObj = {
            ...{
              // freq: (this.modelObj.repeat === 'weekday') ? 'daily' : (this.modelObj.repeat !== 'custom') ? this.modelObj.repeat : this.rawModelObj.rrule.freq,
              freq: (this.modelObj.repeat === 'weekday') ? 'daily' : this.modelObj.repeat,
              byweekday: (this.modelObj.repeat === 'weekday') ? ['mo', 'tu', 'we', 'th', 'fr'] : undefined,
              dtstart: this.modelObj.start,
              until: this.modelObj.end,
            }, ...(this.modelObj.repeat === 'custom' && this.rawModelObj.rrule)
          }
          resolve(resolveObj)
        } else {
          resolve(null)
        }
      })
    },
    // custom-rrule-dialog
    saveRruleDialog (payload) {
      this.saveOrUpdateHandler(payload)
    },
    closeRruleDialog ({ dialog, payload }) {
      this.modelObj.start = payload.start
      this.modelObj.end = payload.end
      this.modelObj.repeat = this.previousRepeatValue
      if (dialog) dialog.close()
    }
  }
}
</script>
<style>
  /* calendar styles */
  .calendar-page th[role=columnheader] div.fc-scrollgrid-sync-inner * {
    color: #1A237E;
    padding: 10px 0;
    font-size: 0.9rem;
  }
  .calendar-page td[role=gridcell] .fc-daygrid-day-number {
    color: black !important;
    margin: 2px 5px 0 0;
    font-size: 0.85rem;
  }
  .calendar-page td[role=gridcell].fc-day span, .fc-daygrid-event span {
    color: white;
    padding-left: 4px;
    font-size: 0.82rem;
  }
  .calendar-page .fc-highlight {
    background: #BBDEFB !important;
  }
  .calendar-page .fc-daygrid-event-harness > a:hover {
    /* color: red !important; */
    background: #1A237E !important;
  }
</style>
